import React, { useState, useEffect } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { TableItemSumberDayaSection } from "../Section/TableItemSumberDayaSection";
import { SubKegiatanApi } from "api";
import Axios from "axios";
import { TableAsetSection, TableItemMaterialManusiaSection } from "../Section";

export const TabItemSumberDaya = ({ type, withPPA }) => {
  const [dropdown, setDropdown] = useState({
    kategori: [],
    itemSumberDayaMaterial: [],
    itemSumberDayaManusia: [],
    itemSumberDayaPeralatan: [],
    itemSumberDayaOverhead: [],
    itemSumberDayaLainnya: [],
    sumberPengadaan: [],
  });

  const getDropdown = () => {
    Axios.all([
      SubKegiatanApi.dropdownKategoriAnggaran(),
      SubKegiatanApi.dropdownItemSumberDayaBuaso({ nama_bahan: "Bahan" }),
      SubKegiatanApi.dropdownItemSumberDayaBuaso({ nama_bahan: "Upah" }),
      SubKegiatanApi.dropdownItemSumberDayaBuaso({ nama_bahan: "Subkon" }),
      SubKegiatanApi.dropdownItemAset({
        nama_grup_aset: "Alat Dan Mesin",
      }),
      SubKegiatanApi.dropdownItemSumberDaya(),
      SubKegiatanApi.dropdownSumberPengadaan(),
    ]).then(
      Axios.spread(
        (
          kategori,
          sumberDayaBahan,
          sumberDayaUpah,
          sumberDayaSubkon,
          sumberDayaAlatMesin,
          itemSumberDaya,
          sumberPengadaan
        ) => {
          const mappingKategori = (data) =>
            data.map((val) => ({
              label: val.nama_kategori_anggaran,
              value: val.id_kategori_anggaran,
            }));

          const mappingItemSumberDayaBuaso = (data) =>
            data.map((val) => ({
              label: val.nama_item,
              value: val.id_item_buaso,
            }));

          const mappingItemSumberDaya = (data) =>
            data.map((val) => ({
              label: val.nama_item_sumber_daya,
              value: val.id_item_sumber_daya,
            }));

          const mappingItemAlatMesin = (data) =>
            data.map((val) => ({
              label: val.nama_item_aset,
              value: val.id_item_aset,
            }));

          const mappingSumberPengadaan = (data) =>
            data.map((val) => ({
              label: val.nama_sumber_pengadaan,
              value: val.id_sumber_pengadaan,
            }));

          setDropdown({
            ...dropdown,
            kategori: mappingKategori(kategori?.data?.data ?? []),
            itemSumberDayaMaterial: mappingItemSumberDayaBuaso(
              sumberDayaBahan?.data?.data ?? []
            ),
            itemSumberDayaManusia: mappingItemSumberDayaBuaso([
              ...sumberDayaUpah?.data?.data,
              ...sumberDayaSubkon?.data?.data,
            ]),

            itemSumberDayaPeralatan: mappingItemAlatMesin(
              sumberDayaAlatMesin?.data?.data ?? []
            ),
            itemSumberDayaOverhead: mappingItemSumberDaya(
              itemSumberDaya?.data?.data ?? []
            ),
            itemSumberDayaLainnya: mappingItemSumberDaya(
              itemSumberDaya?.data?.data ?? []
            ),
            sumberPengadaan: mappingSumberPengadaan(
              sumberPengadaan?.data?.data ?? []
            ),
          });
        }
      )
    );
  };

  useEffect(() => {
    getDropdown();

    return () => {};
  }, []);

  return (
    <Card className="p-0 m-0">
      <Tab.Container defaultActiveKey="material">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="material">Sumber Daya Material</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="manusia">Sumber Daya Manusia</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="peralatan">Sumber Daya Peralatan</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="overhead">
                Sumber Daya Biaya Overhead
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="lainnya">Sumber Daya Lainnya</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="material">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Material</b>
              </span>
              <TableItemMaterialManusiaSection
                dropdown={dropdown}
                tabType="material"
                type={type}
                withPPA={withPPA}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="manusia">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Manusia</b>
              </span>
              <TableItemMaterialManusiaSection
                dropdown={dropdown}
                tabType="manusia"
                type={type}
                withPPA={withPPA}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="peralatan">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Peralatan</b>
              </span>
              <TableAsetSection
                dropdown={dropdown}
                tabType="peralatan"
                type={type}
                withPPA={withPPA}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="overhead">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Biaya Overhead</b>
              </span>
              <TableItemSumberDayaSection
                dropdown={dropdown}
                tabType="overhead"
                type={type}
                withPPA={withPPA}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="lainnya">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Lainnya</b>
              </span>
              <TableItemSumberDayaSection
                dropdown={dropdown}
                tabType="lainnya"
                type={type}
                withPPA={withPPA}
              />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};
