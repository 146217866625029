import Services from "../../services";

class JenisAnggaranAPi {
  get() {
    return Services.get("/kategori_anggaran");
  }

  getPage(params) {
    return Services.get("/kategori_anggaran/page/", { params });
  }

  getKode() {
    return Services.get("/kategori_anggaran/no_baru");
  }

  show(value) {
    return Services.put("/kategori_anggaran/show", value);
  }

  hide(value) {
    return Services.put("/kategori_anggaran/hide", value);
  }

  create(value) {
    return Services.post("/kategori_anggaran", value);
  }

  update(value) {
    return Services.put("/kategori_anggaran", value);
  }

  delete(value) {
    return Services.post("/kategori_anggaran/delete", value);
  }
}

export default new JenisAnggaranAPi();
