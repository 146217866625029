import Services from "../../services";

class SubKelompokAnggaranApi {
  dropdownKelompok() {
    return Services.get("/kelompok_anggaran/dropdown");
  }

  getPage(params) {
    return Services.get("/sub_kelompok_anggaran/page/", { params });
  }

  getKode() {
    return Services.get("/sub_kelompok_anggaran/no_baru");
  }

  show(value) {
    return Services.put("/sub_kelompok_anggaran/show", value);
  }

  hide(value) {
    return Services.put("/sub_kelompok_anggaran/hide", value);
  }

  create(value) {
    return Services.post("/sub_kelompok_anggaran", value);
  }

  update(value) {
    return Services.put("/sub_kelompok_anggaran", value);
  }

  delete(value) {
    return Services.post("/sub_kelompok_anggaran/delete", value);
  }
}

export default new SubKelompokAnggaranApi();
