import { InfoItemHorizontal } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoPPASection = () => {
  const { values } = useFormikContext();

  return (
    <>
      <Row>
        <Col lg="4">
          <InfoItemHorizontal
            label="Tgl. PPA"
            text={
              values?.kegiatan?.tglKegiatan
                ? DateConvert(new Date(values?.kegiatan?.tglKegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. PPA"
            text={values?.kegiatan?.noKegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Deskripsi"
            text={values?.kegiatan?.namaKegiatan ?? "-"}
          />
        </Col>
      </Row>
    </>
  );
};
