export const formSubmmitMapper = (data) => ({
  id_ppa: data?.subKegiatan?.idPPA,
  id_sub_kegiatan: data?.subKegiatan?.idSubKegiatan,
  tgl_ppa: data?.PPA?.tglPPA,
  deskripsi: data?.PPA?.deskripsiPPA,
  material: data.subKegiatan.detailMaterial
    ? data.subKegiatan.detailMaterial.map((val) => ({
        id_item_bahan: val?.idItemSumberDaya,
        qty_ppa_material: val?.qty,
        harga_satuan_material: val?.hargaSatuan,
        harga_satuan: val.hargaSatuanPPA
          ? val.hargaSatuanPPA
          : val?.hargaSatuan,
        id_item_sub_kegiatan_material: val?.idItemSubKegiatan,
        id_sub_kegiatan: data?.subKegiatan?.idSubKegiatan,
      }))
    : [],
  manusia: data.subKegiatan.detailManusia
    ? data.subKegiatan.detailManusia.map((val) => ({
        id_item_upah: val.idItemSumberDaya,
        qty_ppa_manusia: val?.qty,
        harga_satuan_manusia: val?.hargaSatuan,
        harga_satuan: val.hargaSatuanPPA
          ? val.hargaSatuanPPA
          : val?.hargaSatuan,
        id_item_sub_kegiatan_manusia: val?.idItemSubKegiatan,
        id_sub_kegiatan: data?.subKegiatan?.idSubKegiatan,
      }))
    : [],
  peralatan: data.subKegiatan.detailPeralatan
    ? data.subKegiatan.detailPeralatan.map((val) => ({
        id_item_alat_mesin: val?.idItemSumberDaya,
        qty_ppa_peralatan: val?.qty,
        harga_satuan_peraltan: val?.hargaSatuan,
        harga_satuan: val.hargaSatuanPPA
          ? val.hargaSatuanPPA
          : val?.hargaSatuan,
        id_item_sub_kegiatan_peralatan: val?.idItemSubKegiatan,
        id_sub_kegiatan: data?.subKegiatan?.idSubKegiatan,
      }))
    : [],
  overhead: data.subKegiatan.detailOverhead
    ? data.subKegiatan.detailOverhead.map((val) => ({
        id_item_sumber_daya: val?.idItemSumberDaya,
        qty_ppa_overhead: val?.qty,
        harga_satuan_overhead: val?.hargaSatuan,
        harga_satuan: val.hargaSatuanPPA
          ? val.hargaSatuanPPA
          : val?.hargaSatuan,
        id_item_sub_kegiatan_overhead: val?.idItemSubKegiatan,
        id_sub_kegiatan: data?.subKegiatan?.idSubKegiatan,
      }))
    : [],
  lainnya: data.subKegiatan.detailLainnya
    ? data.subKegiatan.detailLainnya.map((val) => ({
        id_item_sumber_daya: val?.idItemSumberDaya,
        qty_ppa_lainnya: val?.qty,
        harga_satuan_lainnya: val?.hargaSatuan,
        harga_satuan: val.hargaSatuanPPA
          ? val.hargaSatuanPPA
          : val?.hargaSatuan,
        id_item_sub_kegiatan_lainnya: val?.idItemSubKegiatan,
        id_sub_kegiatan: data?.subKegiatan?.idSubKegiatan,
      }))
    : [],
});
