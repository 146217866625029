import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Td,
  DataStatus,
  TdFixed,
  InputQtyDecimal,
  ThFixed,
  Tr,
  THead,
  TBody,
  ActionButton,
  SelectSearch,
  CreateButton,
  UpdateButton,
  DeleteButton,
  NumberFormat,
} from "components";
import { useLocation } from "react-router-dom";
import { Formik, useFormikContext } from "formik";

import { SubKegiatanApi } from "api";
import * as Yup from "yup";
import { ButtonGroup } from "react-bootstrap";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDownload,
  AiOutlineEye,
} from "react-icons/ai";
import { DecimalConvert, RupiahConvert } from "utilities";
import { ModalUpload } from "../Comps";

export const TableItemMaterialManusiaSection = ({
  dropdownItem,
  dropdownPengadaan,
  type,
  tabType,
  withPPA,
}) => {
  const [update, setUpdate] = useState("");
  const { values, setValues } = useFormikContext();
  const [modalUploadConfig, setModalUploadConfig] = useState({
    show: false,
    index: null,
    data: {},
  });
  const checkKeyTab =
    tabType === "material" ? "detailMaterial" : "detailManusia";

  const TableForm = ({ data }) => {
    const tableFormIntialValues = {
      idKategori: data?.idKategori ?? null,
      namaKategori: data?.namaKategori ?? null,
      idItemSumberDaya: data?.idItemSumberDaya ?? null,
      namaItemSumberDaya: data?.namaItemSumberDaya ?? null,
      namaSatuan: data?.namaSatuan ?? null,
      idSatuan: data?.idSatuan ?? null,
      qty: parseFloat(data?.qty ?? 0),
      idSumberPengadaan: data?.idSumberPengadaan ?? null,
      namaSumberPengadaan: data?.namaSumberPengadaan ?? null,
      hargaSatuan: parseFloat(data?.hargaSatuan ?? 0),
    };
    const tableValidationSchema = Yup.object().shape({
      qty: Yup.string().required("Masukan qty").nullable(),
      idItemSumberDaya: Yup.string()
        .required("Pilih item sumber daya")
        .nullable(),
      idSumberPengadaan: Yup.string()
        .required("Pilih item sumber pengadaan")
        .nullable(),
      hargaSatuan: Yup.string().required("Masukan harga satuan").nullable(),
    });

    const setSubmitHandler = (value, key) => {
      if (data) {
        setValues({
          ...values,
          subKegiatan: {
            ...values.subKegiatan,
            [key]: values?.subKegiatan?.[key].map((val, index) =>
              update === index ? value : val
            ),
          },
        });
        setUpdate(undefined);
      }
      if (!data) {
        setValues({
          ...values,
          subKegiatan: {
            ...values?.subKegiatan,
            [key]: [value, ...values?.subKegiatan?.[key]],
          },
        });
      }
    };
    const tableSubmitHandler = (value) => {
      tabType === "material" && setSubmitHandler(value, "detailMaterial");
      tabType === "manusia" && setSubmitHandler(value, "detailManusia");
    };

    return (
      <Formik
        enableReinitialize
        initialValues={tableFormIntialValues}
        validationSchema={tableValidationSchema}
        onSubmit={tableSubmitHandler}
      >
        {({ values, setValues, errors, touched, handleSubmit }) => (
          <Tr>
            <Td colSpan="2">
              <SelectSearch
                placeholder="Pilih item material"
                option={dropdownItem}
                defaultValue={{
                  value: values.idItemSumberDaya ?? "",
                  label: values.namaItemSumberDaya ?? "Pilih item material",
                }}
                onChange={(val) => {
                  setValues({
                    ...values,
                    idItemSumberDaya: val.value,
                    namaItemSumberDaya: val.label,
                    namaSatuan: val.namaSatuan,
                  });
                }}
                error={
                  Boolean(errors.idItemSumberDaya) && touched.idItemSumberDaya
                }
                errorText={
                  Boolean(errors.idItemSumberDaya) &&
                  touched.idItemSumberDaya &&
                  errors.idItemSumberDaya
                }
              />
            </Td>
            <Td>{values?.namaSatuan ?? "-"}</Td>
            <Td>
              <SelectSearch
                placeholder="Pilih sumber pengadaan"
                option={dropdownPengadaan}
                defaultValue={{
                  value: values.idSumberPengadaan ?? "",
                  label: values.namaSumberPengadaan ?? "Pilih sumber pengadaan",
                }}
                onChange={(val) => {
                  setValues({
                    ...values,
                    idSumberPengadaan: val.value,
                    namaSumberPengadaan: val.label,
                  });
                }}
                error={
                  Boolean(errors.idSumberPengadaan) && touched.idSumberPengadaan
                }
                errorText={
                  Boolean(errors.idSumberPengadaan) &&
                  touched.idSumberPengadaan &&
                  errors.idSumberPengadaan
                }
              />
            </Td>
            <Td>
              <InputQtyDecimal
                placeholder="Masukan qty"
                value={values?.qty ?? null}
                onChange={(e) => setValues({ ...values, qty: e })}
                error={Boolean(errors.qty) && touched.qty}
                errorText={Boolean(errors.qty) && touched.qty && errors.qty}
              />
            </Td>
            <Td>
              <NumberFormat
                placeholder="Masukan harga satuan"
                value={values?.hargaSatuan}
                decimalSeparator=","
                onChange={(e) => setValues({ ...values, hargaSatuan: e })}
                error={Boolean(errors.hargaSatuan) && touched.hargaSatuan}
                errorText={
                  Boolean(errors.hargaSatuan) &&
                  touched.hargaSatuan &&
                  errors.hargaSatuan
                }
              />
            </Td>
            <Td>
              {
                RupiahConvert(
                  String(
                    parseFloat(values.hargaSatuan ?? 0) *
                      parseFloat(values.qty ?? 0)
                  )
                ).getWithComa
              }
            </Td>
            <TdFixed>
              {data ? (
                <>
                  <ButtonGroup>
                    <ActionButton
                      text={<AiOutlineCheck />}
                      variant="success"
                      onClick={handleSubmit}
                      size="sm"
                    />
                    <ActionButton
                      text={<AiOutlineClose />}
                      variant="danger"
                      onClick={() => setUpdate(undefined)}
                      size="sm"
                    />
                  </ButtonGroup>
                </>
              ) : (
                <CreateButton
                  noText
                  icon
                  className="px-4"
                  onClick={handleSubmit}
                  size="sm"
                />
              )}
            </TdFixed>
          </Tr>
        )}
      </Formik>
    );
  };

  return (
    <>
      <Table>
        <THead>
          <Tr>
            <Th>No</Th>
            <Th style={{ textTransform: "capitalize" }}>Item {tabType}</Th>
            <Th>Satuan</Th>
            <Th>Sumber Pengadaan</Th>
            <Th>Qty.</Th>
            {type !== "read" && (
              <>
                <Th>Qty. Terealisasi</Th>
                <Th>Sisa</Th>
              </>
            )}
            <Th>Qty. Realisasi</Th>
            <Th>Harga Satuan PPA</Th>
            <Th>Sub Total</Th>
            {type !== "read" && (
              <>
                <Th>Nominal Terealisasi</Th>
                <Th>Sisa</Th>
              </>
            )}
            <Th>Nominal Realisasi</Th>
            <ThFixed>Bukti</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {values?.subKegiatan?.[checkKeyTab]?.length > 0 ? (
            values?.subKegiatan?.[checkKeyTab]?.map((val, index) =>
              update === index ? (
                <TableForm data={val} />
              ) : (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>{val.namaItemSumberDaya ?? "-"}</Td>
                  <Td>{val.namaSatuan ?? "-"}</Td>
                  <Td>{val.namaSumberPengadaan ?? "-"}</Td>
                  <Td className="text-right">
                    {DecimalConvert(parseFloat(val.qtyPPA ?? 0)).getWithComa}
                  </Td>
                  {type !== "read" && (
                    <>
                      <Td className="text-right">
                        {
                          DecimalConvert(parseFloat(val.qtyTerealisasi ?? 0))
                            .getWithComa
                        }
                      </Td>
                      <Td className="text-right">
                        {
                          DecimalConvert(
                            parseFloat(val.qtyPPA ?? 0) -
                              parseFloat(val.qtyTerealisasi ?? 0)
                          ).getWithComa
                        }
                      </Td>
                    </>
                  )}
                  <Td className="text-right">
                    {type !== "read" ? (
                      <InputQtyDecimal
                        placeholder="Masukan qty"
                        value={val?.qtyRealisasi}
                        onChange={(e) => {
                          const qtySisa =
                            parseFloat(val.qty ?? 0) -
                            parseFloat(val.qtyTerealisasi ?? 0);

                          setValues({
                            ...values,
                            subKegiatan: {
                              ...values.subKegiatan,
                              [checkKeyTab]: values?.subKegiatan?.[
                                checkKeyTab
                              ]?.map((res, i) =>
                                index === i
                                  ? {
                                      ...res,
                                      qtyRealisasi:
                                        qtySisa < parseFloat(e) ? qtySisa : e,
                                    }
                                  : res
                              ),
                            },
                          });
                        }}
                      />
                    ) : (
                      DecimalConvert(parseFloat(val.qtyRealisasi ?? 0))
                        .getWithComa
                    )}
                  </Td>

                  <Td className="text-right">
                    {
                      RupiahConvert(
                        parseFloat(val?.hargaSatuanPPA ?? 0).toString()
                      ).getWithComa
                    }
                  </Td>

                  <Td className="text-right">
                    {
                      RupiahConvert(
                        String(
                          parseFloat(val.hargaSatuanPPA ?? 0) *
                            parseFloat(val.qtyPPA ?? 0)
                        )
                      ).getWithComa
                    }
                  </Td>
                  {type !== "read" && (
                    <>
                      <Td className="text-right">
                        {
                          RupiahConvert(
                            String(parseFloat(val.nominalTerealisasi ?? 0))
                          ).getWithComa
                        }
                      </Td>
                      <Td className="text-right">
                        {
                          RupiahConvert(
                            String(
                              parseFloat(val.hargaSatuanPPA ?? 0) *
                                parseFloat(val.qtyPPA ?? 0) -
                                parseFloat(val.nominalTerealisasi ?? 0)
                            )
                          ).getWithComa
                        }
                      </Td>
                    </>
                  )}
                  <Td className="text-right">
                    {type !== "read" ? (
                      <NumberFormat
                        placeholder="Masukan nominal terealisasi"
                        value={val.nominalRealisasi}
                        decimalSeparator=","
                        onChange={(e) => {
                          const nominalSisa =
                            parseFloat(val.hargaSatuanPPA ?? 0) *
                              parseFloat(val.qtyPPA ?? 0) -
                            parseFloat(val.nominalTerealisasi ?? 0);
                          setValues({
                            ...values,
                            subKegiatan: {
                              ...values.subKegiatan,
                              [checkKeyTab]: values?.subKegiatan?.[
                                checkKeyTab
                              ]?.map((res, i) =>
                                index === i
                                  ? {
                                      ...res,
                                      nominalRealisasi:
                                        nominalSisa < parseFloat(e)
                                          ? nominalSisa
                                          : e,
                                    }
                                  : res
                              ),
                            },
                          });
                        }}
                      />
                    ) : (
                      RupiahConvert(
                        parseFloat(val.nominalRealisasi ?? 0).toString()
                      ).getWithComa
                    )}
                  </Td>
                  {console.log(val)}
                  {console.log(val.nominalRealisasi)}
                  <TdFixed>
                    <ButtonGroup>
                      <ActionButton
                        text={
                          type === "read" ? (
                            <AiOutlineEye />
                          ) : (
                            <AiOutlineDownload />
                          )
                        }
                        onClick={() =>
                          setModalUploadConfig({
                            show: true,
                            index: index,
                            data: val,
                          })
                        }
                        size="sm"
                      />
                    </ButtonGroup>
                  </TdFixed>
                </Tr>
              )
            )
          ) : (
            <tr>
              <td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </TBody>
      </Table>
      <ModalUpload
        setModalConfig={setModalUploadConfig}
        modalConfig={modalUploadConfig}
        tabKey={checkKeyTab}
        readOnly={type === "read" ? true : false}
      />
    </>
  );
};
