import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { CatatanApprovalSection, FormSection, InfoSection } from "../Section";
import { Formik } from "formik";
import {
  formInitialValues,
  formSubmmitMapper,
  formValidationSchema,
} from "../Utils";
import { ActionButton, DataStatus } from "components";
import { ProgramApi } from "api";

export const ModalAction = ({
  setModalConfig,
  modalConfig,
  dropdown,
  formSubmitHandler,
}) => {
  const [dataDetail, setDataDetail] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(true);

  const getDataSingle = () => {
    ProgramApi.getSingle({ id_program: modalConfig.id })
      .then(({ data }) => {
        setDataDetail(data?.data);
      })
      .catch(() => {})
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  useEffect(() => {
    getDataSingle();

    return () => {
      setIsModalLoading(false);
    };
  }, []);

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ data: {}, type: "add", show: false })}
        size="xl"
      >
        <Formik
          enableReinitialize
          initialValues={formInitialValues(dataDetail)}
          // validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ isSubmitting, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                {modalConfig.type === "edit"
                  ? "Ubah"
                  : modalConfig.type === "detail"
                  ? "Detail"
                  : "Tambah"}{" "}
                Program
              </Modal.Header>
              <Modal.Body>
                {isModalLoading ? (
                  <DataStatus loading text="Memuat Data" />
                ) : modalConfig?.type === "detail" ? (
                  <>
                    <InfoSection />
                    <CatatanApprovalSection data={dataDetail?.stakeholder} />
                  </>
                ) : (
                  <>
                    <FormSection dropdown={dropdown} />
                    {modalConfig.type !== "add" && (
                      <CatatanApprovalSection data={dataDetail?.stakeholder} />
                    )}
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  text="Batal"
                  variant="outline-secondary"
                  size="sm"
                  onClick={() =>
                    modalConfig?.type === "detail"
                      ? setModalConfig({ ...modalConfig, show: false })
                      : resetForm()
                  }
                />
                {modalConfig?.type !== "detail" && (
                  <ActionButton
                    text={modalConfig.type === "edit" ? "Ubah" : "Simpan"}
                    type="submit"
                    size="sm"
                    variant={
                      modalConfig.type === "edit" ? "success" : "primary"
                    }
                    loading={isSubmitting}
                  />
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
