import { DateConvert } from "utilities";

export const formInitialValues = (data) => ({
  idProgram: data?.id_program ?? undefined,
  namaProgram: data?.nama_program ?? "",
  deskripsi: data?.deskripsi_program ?? "",
  tglProgram: data?.tgl_program ?? null,
  idPenanggungJawab: data?.id_penanggung_jawab_program ?? undefined,
  namaPenanggungJawab: data?.nama_penanggung_jawab_program ?? "",
  idUnitOrganisasi: data?.id_unit_organisasi ?? undefined,
  namaUnitOrganisasi: data?.nama_unit_organisasi ?? "",
  idUnitProduksi: data?.id_unit_produksi ?? undefined,
  namaUnitProduksi: data?.nama_unit_produksi ?? "",
  periodeMulai: data?.periode_mulai_program ?? null,
  periodeSelesai: data?.periode_selesai_program ?? null,
  tglInput: data?.tgl_input ?? DateConvert(new Date()).default,
  noProgram: data?.no_program ?? "",
  lamaPeriode: data?.lama_periode_program ?? "",
});
