import Services from "services";

class LaporanProyeksiLabaRugiApi {
  getListCoa(params) {
    return Services.get("/laporan_proyeksi_laba_rugi/coa", { params });
  }
  getRealisasiProduksi(params) {
    return Services.get("/laporan_proyeksi_laba_rugi/realisasi", { params });
  }
  getDetailRealisasiProduksi(params) {
    return Services.get("/laporan_proyeksi_laba_rugi/detail_realisasi", {
      params,
    });
  }
}

export default new LaporanProyeksiLabaRugiApi();
