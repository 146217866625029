import Services from "../../services";

class KegiatanApi {
  dropdownUnitOrganisasi() {
    return Services.get("/unit_organisasi/dropdown");
  }

  dropdownKaryawan() {
    return Services.get("/karyawan/dropdown");
  }

  dropdownJenisAnggaran() {
    return Services.get("/jenis_anggaran/dropdown");
  }

  dropdownKelompokAnggaran(params) {
    return Services.get("/kelompok_anggaran/dropdown", { params });
  }

  dropdownSubKelompok(params) {
    return Services.get("/sub_kelompok_anggaran/dropdown", { params });
  }

  getPage(params) {
    return Services.get("/kegiatan/", { params });
  }

  getPageProgram(params) {
    return Services.get("/program/", { params });
  }

  getSingle(params) {
    return Services.get("kegiatan/single/", { params });
  }

  getSingleProgram(params) {
    return Services.get("detail", { params });
  }

  getKode(params) {
    return Services.get("/kegiatan/no_baru", { params });
  }

  show(value) {
    return Services.put("/kegiatan/show", value);
  }

  hide(value) {
    return Services.put("/kegiatan/hide", value);
  }

  create(value) {
    return Services.post("/kegiatan", value);
  }

  update(value) {
    return Services.post("/kegiatan", value);
  }

  delete(value) {
    return Services.post("/kegiatan/delete", value);
  }
}

export default new KegiatanApi();
