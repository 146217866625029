import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { ProgramApi } from "api";

export const ModalFilterProgram = ({
  show,
  setShow,
  data,
  setData,
  dropdown,
}) => {
  // STATE DATA
  const dataUnitOrganisasi = [
    { label: "Semua", value: undefined },
    ...dropdown?.unitOrganisasi,
  ];

  const dataPenanggungJawab = [
    { label: "Semua", value: undefined },
    ...dropdown?.penanggungJawab,
  ];
  const dataStatusOpname = [
    { label: "Semua", value: undefined },
    {
      label: "BELUM DIOPNAME",
      value: false,
    },
    {
      label: "SUDAH DIOPNAME",
      value: true,
    },
  ];
  const dataApproval = [
    { label: "Semua", value: undefined },
    {
      value: "APP",
      label: "APPROVED",
    },
    {
      value: "VER",
      label: "VERIFIED",
    },
    {
      value: "REV",
      label: "REVISI",
    },
    {
      value: "REJ",
      label: "REJECT",
    },
    {
      value: "PEN",
      label: "PENDING",
    },
  ];

  // FORM VALUES
  const formInitialValues = {
    tgl_program_start: data?.filter?.tgl_program_start,
    tgl_program_end: data?.filter?.tgl_program_end,
    nama_unit_organisasi: data?.filter?.nama_unit_organisasi,
    id_penanggung_jawab_program: data?.filter?.id_penanggung_jawab_program,
    status_approval_program: data?.filter?.status_approval_program,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_program_start: startDate,
      tgl_program_end: endDate,
    });
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_program_start: undefined,
      tgl_program_end: undefined,
      nama_unit_organisasi: undefined,
      id_penanggung_jawab_program: undefined,
      status_approval_program: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Program"
            placeholderText="Pilih tanggal program"
            startDate={
              values.tgl_program_start ? new Date(values.tgl_program_start) : ""
            }
            endDate={
              values.tgl_program_end ? new Date(values.tgl_program_end) : ""
            }
            onChange={(dates) => onTanggalChange(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.nama_unit_organisasi}
            label="Unit Organisasi"
            placeholder="Pilih unit organisasi"
            defaultValue={dataUnitOrganisasi?.find(
              (val) => val.label === values.nama_unit_organisasi
            )}
            option={dataUnitOrganisasi}
            onChange={(val) => {
              setFieldValue("nama_unit_organisasi", val.label);
            }}
          />
          <SelectSearch
            key={values.id_penanggung_jawab_program}
            label="Penanggung Jawab"
            placeholder="Pilih penanggung jawab"
            defaultValue={dataPenanggungJawab.find(
              (val) => val.value === values.id_penanggung_jawab_program
            )}
            option={dataPenanggungJawab}
            onChange={(val) =>
              setFieldValue("id_penanggung_jawab_program", val.value)
            }
          />
          {/* <SelectSearch
            key="3"
            label="Status Approval"
            placeholder="Pilih Status Approval"
            defaultValue={dataApproval.find(
              (val) => val.value === values.status_approval_program
            )}
            option={dataApproval}
            onChange={(val) =>
              setFieldValue("status_approval_program", val.value)
            }
          /> */}
        </FilterModal>
      )}
    </Formik>
  );
};
