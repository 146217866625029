import { InfoItemHorizontal } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoProgramSection = () => {
  const { values } = useFormikContext();
  return (
    <>
      <Row>
        <Col lg="6">
          <InfoItemHorizontal
            label="Tgl. Program"
            text={
              values?.program?.tglProgram
                ? DateConvert(new Date(values?.program?.tglProgram)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Program"
            text={values?.program?.noProgram ?? "-"}
          />
          <InfoItemHorizontal
            label="Nama Program"
            text={values?.program?.namaProgram ?? "-"}
          />
          <InfoItemHorizontal
            label="Unit Organisasi"
            text={values?.program?.namaUnitOrganisasiProgram ?? "-"}
          />
        </Col>
        <Col lg="6">
          <InfoItemHorizontal
            label="Penanggung Jawab"
            text={values?.program?.namaPenanggungJawabProgram ?? "-"}
          />
          <InfoItemHorizontal
            label="Periode Mulai"
            text={
              values?.program?.periodeMulaiProgram
                ? DateConvert(new Date(values?.program?.periodeMulaiProgram))
                    .detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Periode Selesai"
            text={
              values?.program?.periodeSelesaiProgram
                ? DateConvert(new Date(values?.program?.periodeSelesaiProgram))
                    .detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Lama Periode"
            text={`${values?.program?.lamaPeriodeProgram ?? "-"} Hari`}
          />
          <InfoItemHorizontal
            label="Deskripsi"
            text={values?.program?.deskripsiProgram ?? "-"}
          />
        </Col>
      </Row>
    </>
  );
};
