import { KelompokAnggaranApi } from "api";
import {
  ActionButton,
  InfoItemHorizontal,
  Input,
  SelectSearch,
  TextArea,
} from "components";
import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

export const ModalAction = ({ setModalConfig, modalConfig, dropdown }) => {
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const {
    values,
    handleChange,
    setValues,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    resetForm,
  } = useFormikContext();

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ data: {}, type: "add", show: false })}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>Mapping COA Item Sumber Daya</Modal.Header>
          <Modal.Body>
            <InfoItemHorizontal
              label="Item Sumber Daya"
              text={values?.namaItem ?? "-"}
            />
            <InfoItemHorizontal
              label="Kategori Anggaran"
              text={values?.namaKategori ?? "-"}
            />
            <hr />
            <SelectSearch
              label="COA"
              placeholder="Pilih COA"
              option={dropdown}
              loading={isNomorLoading}
              onChange={(val) =>
                setValues({
                  ...values,
                  noCoa: val?.value,
                  namaCoa: val?.label,
                })
              }
              defaultValue={
                values?.noCoa && {
                  value: values.noCoa,
                  label: values.namaCoa,
                }
              }
              error={Boolean(errors.noCoa) && touched.noCoa}
              errorText={Boolean(errors.noCoa) && touched.noCoa && errors.noCoa}
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <ActionButton
              text="Batal"
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                resetForm({
                  values: {
                    namaItem: "",
                    namaKategori: "",
                    idItemSumberDaya: "",
                    noCoa: "",
                  },
                })
              }
            /> */}
            <ActionButton
              text={modalConfig.type === "edit" ? "Ubah" : "Simpan"}
              type="submit"
              size="sm"
              variant={modalConfig.type === "edit" ? "success" : "primary"}
              loading={isSubmitting}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
