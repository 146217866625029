import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { InfoKegiatanSection, InfoProgramSection } from "../Section";
import { Formik } from "formik";
import {
  formInitialValues,
  formSubmmitMapper,
  formValidationSchema,
} from "../Utils";
import { ActionButton, DataStatus, InfoItemHorizontal } from "components";
import { SubKegiatanApi } from "api";
import { DateConvert } from "utilities";

export const ModalDetailKegiatan = ({
  setModalConfig,
  modalConfig,
  dropdown,
  formSubmitHandler,
}) => {
  const [dataDetail, setDataDetail] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(true);

  const getDataSingle = () => {
    SubKegiatanApi.getDetail({ id: modalConfig.id, arg: "sk" })
      .then(({ data }) => {
        setDataDetail(data?.data);
      })
      .catch(() => {})
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  useEffect(() => {
    getDataSingle();

    return () => {
      setIsModalLoading(false);
    };
  }, []);

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ data: {}, type: "add", show: false })}
        size="xl"
      >
        <Formik
          enableReinitialize
          initialValues={formInitialValues(dataDetail)}
          // validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>Detail Kegiatan</Modal.Header>
              <Modal.Body>
                {isModalLoading ? (
                  <DataStatus loading text="Memuat Data" />
                ) : (
                  <>
                    <Row>
                      <Col lg="6">
                        <InfoItemHorizontal
                          label="Tgl. Program"
                          text={
                            values?.program?.tglProgram
                              ? DateConvert(
                                  new Date(values?.program?.tglProgram)
                                ).detail
                              : "-"
                          }
                        />
                        <InfoItemHorizontal
                          label="No. Program"
                          text={values?.program?.noProgram ?? "-"}
                        />
                        <InfoItemHorizontal
                          label="Nama Program"
                          text={values?.program?.namaProgram ?? "-"}
                        />
                        <InfoItemHorizontal
                          label="Unit Organisasi"
                          text={
                            values?.program?.namaUnitOrganisasiProgram ?? "-"
                          }
                        />
                      </Col>
                      <Col lg="6">
                        <InfoItemHorizontal
                          label="Penanggung Jawab"
                          text={
                            values?.program?.namaPenanggungJawabProgram ?? "-"
                          }
                        />
                        <InfoItemHorizontal
                          label="Periode Mulai"
                          text={
                            values?.program?.periodeMulaiProgram
                              ? DateConvert(
                                  new Date(values?.program?.periodeMulaiProgram)
                                ).detail
                              : "-"
                          }
                        />
                        <InfoItemHorizontal
                          label="Periode Selesai"
                          text={
                            values?.program?.periodeSelesaiProgram
                              ? DateConvert(
                                  new Date(
                                    values?.program?.periodeSelesaiProgram
                                  )
                                ).detail
                              : "-"
                          }
                        />
                        <InfoItemHorizontal
                          label="Lama Periode"
                          text={`${
                            values?.program?.lamaPeriodeProgram ?? "-"
                          } Hari`}
                        />
                        <InfoItemHorizontal
                          label="Deskripsi"
                          text={values?.program?.deskripsiProgram ?? "-"}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col lg="6">
                        <InfoItemHorizontal
                          label="Tgl. Kegiatan"
                          text={
                            values?.kegiatan?.tglKegiatan
                              ? DateConvert(
                                  new Date(values?.kegiatan?.tglKegiatan)
                                ).detail
                              : "-"
                          }
                        />
                        <InfoItemHorizontal
                          label="No. Kegiatan"
                          text={values?.kegiatan?.noKegiatan ?? "-"}
                        />
                        <InfoItemHorizontal
                          label="Nama Kegiatan"
                          text={values?.kegiatan?.namaKegiatan ?? "-"}
                        />
                        <InfoItemHorizontal
                          label="Penanggung Jawab"
                          text={
                            values?.kegiatan?.namaPenanggungJawabKegiatan ?? "-"
                          }
                        />
                      </Col>
                      <Col lg="6">
                        <InfoItemHorizontal
                          label="Periode Mulai"
                          text={
                            values?.kegiatan?.periodeMulaiKegiatan
                              ? DateConvert(
                                  new Date(
                                    values?.kegiatan?.periodeMulaiKegiatan
                                  )
                                ).detail
                              : "-"
                          }
                        />
                        <InfoItemHorizontal
                          label="Periode Selesai"
                          text={
                            values?.kegiatan?.periodeSelesaiKegiatan
                              ? DateConvert(
                                  new Date(
                                    values?.kegiatan?.periodeSelesaiKegiatan
                                  )
                                ).detail
                              : "-"
                          }
                        />
                        <InfoItemHorizontal
                          label="Lama Periode"
                          text={`${
                            values?.kegiatan?.lamaPeriodeKegiatan ?? "-"
                          } Hari`}
                        />
                        <InfoItemHorizontal
                          label="Deskripsi"
                          text={values?.kegiatan?.deskripsiKegiatan ?? "-"}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <InfoItemHorizontal
                      label="Jenis Anggaran"
                      text={values?.kegiatan?.namaJenisAnggaran ?? "-"}
                    />
                    <InfoItemHorizontal
                      label="Kelompok Anggaran"
                      text={values?.kegiatan?.namaKelompokAnggaran ?? "-"}
                    />
                    <InfoItemHorizontal
                      label="Sub Kelompok Anggaran"
                      text={values?.kegiatan?.namaSubKelompokAnggaran ?? "-"}
                    />
                  </>
                )}
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
