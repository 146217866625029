import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  InfoKegiatanSection,
  InfoProgramSection,
  InfoSubKegiatanSection,
} from "../Section";
import { Formik } from "formik";
import {
  formInitialValues,
  formSubmmitMapper,
  formValidationSchema,
} from "../Utils";
import { ActionButton, DataStatus, InfoItemHorizontal } from "components";
import { SubKegiatanApi } from "api";
import { DateConvert } from "utilities";
import { TableItemSumberDayaSection } from "../Section/TableItemSumberDayaSection";
import { TabItemSumberDaya } from "./TabItemSumberDaya";

export const ModalDetailKegiatan = ({
  setModalConfig,
  modalConfig,
  dropdown,
  formSubmitHandler,
}) => {
  const [dataDetail, setDataDetail] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(true);

  const getDataSingle = () => {
    SubKegiatanApi.getDetail({ id: modalConfig.id, arg: "ppa" })
      .then(({ data }) => {
        setDataDetail(data?.data);
      })
      .catch(() => {})
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  useEffect(() => {
    getDataSingle();

    return () => {
      setIsModalLoading(false);
    };
  }, []);

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ data: {}, type: "add", show: false })}
        size="xl"
      >
        <Formik
          enableReinitialize
          initialValues={formInitialValues(dataDetail)}
          // validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>Detail Kegiatan</Modal.Header>
              <Modal.Body>
                {isModalLoading ? (
                  <DataStatus loading text="Memuat Data" />
                ) : (
                  <>
                    <InfoProgramSection />
                    <hr />
                    <InfoKegiatanSection />
                    <hr />
                    <InfoSubKegiatanSection />
                    <hr />
                    <span style={{ fontSize: "14px" }}>
                      <b>List Item Sumber Daya</b>
                    </span>
                    <TabItemSumberDaya type="read" />
                  </>
                )}
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
