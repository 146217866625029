import * as Yup from "yup";

export const formValidationSchema = () =>
  Yup.object().shape({
    kegiatan: Yup.object().shape({
      tglKegiatan: Yup.string().required("Masukan tgl kegiatan").nullable(),
      namaKegiatan: Yup.string().required("Masukan nama kegiatan").nullable(),
      periodeMulaiKegiatan: Yup.string()
        .required("Masukan peiode mulai")
        .nullable(),
      periodeSelesaiKegiatan: Yup.string()
        .required("Masukan periode selesai")
        .nullable(),
      idPenanggungJawabKegiatan: Yup.string()
        .required("Pilih penanggung jawab")
        .nullable(),
      idJenisAnggaran: Yup.string().required("Pilih jenis anggaran").nullable(),
      idKelompokAnggaran: Yup.string()
        .required("Pilih kelompok anggaran")
        .nullable(),
      idSubKelompokAnggaran: Yup.string()
        .required("Pilih sub kelompok anggaran")
        .nullable(),
      deskripsiKegiatan: Yup.string().required("Masukan deskripsi").nullable(),
    }),
  });
