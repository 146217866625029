import React from "react";
import { Card, Modal, Nav, Tab } from "react-bootstrap";
import TabFile from "./TabFile";
import TabGambar from "./TabGambar";
import { InfoItemHorizontal } from "components";
import { DecimalConvert } from "utilities";
// import GambarSecton from "./GambarSection";

export const ModalUpload = ({
  modalConfig,
  setModalConfig,
  tabKey,
  readOnly,
}) => {
  return (
    <Modal
      show={modalConfig?.show}
      onHide={() => setModalConfig({ ...modalConfig, show: false })}
      size="lg"
    >
      <Modal.Header closeButton>Bukti Realisasi PPA</Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <InfoItemHorizontal
            label="Item Sumber Daya"
            text={modalConfig?.data?.namaItemSumberDaya ?? "-"}
          />
          <InfoItemHorizontal
            label="Qty"
            text={
              DecimalConvert(parseFloat(modalConfig?.data?.qtyPPA ?? 0))
                .getWithComa
            }
          />
          <InfoItemHorizontal
            label="Satuan"
            text={modalConfig?.data?.namaSatuan ?? "-"}
          />
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="tab-1">Gambar</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tab-2">File</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="tab-1">
              <div className="m-3">
                <TabGambar
                  tabKey={tabKey}
                  index={modalConfig.index}
                  data={modalConfig.data}
                  readOnly={Boolean(readOnly)}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab-2">
              <div className="m-3">
                <TabFile
                  tabKey={tabKey}
                  index={modalConfig.index}
                  data={modalConfig.data}
                  readOnly={Boolean(readOnly)}
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};
