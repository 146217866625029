import Services from "../../services";

class ItemSumberDayaApi {
  dropdownUnitOrganisasi() {
    return Services.get("/unit_organisasi/dropdown");
  }

  dropdownUnitProduksi() {
    return Services.get("/dropdown/unit_produksi");
  }

  dropdownKaryawan() {
    return Services.get("/karyawan/dropdown");
  }

  getPage(params) {
    return Services.get("/program/", { params });
  }

  getSingle(params) {
    return Services.get("program/single/", { params });
  }

  getKode(params) {
    return Services.get("/program/no_baru", { params });
  }

  show(value) {
    return Services.put("/program/show", value);
  }

  hide(value) {
    return Services.put("/program/hide", value);
  }

  create(value) {
    return Services.post("/program", value);
  }

  update(value) {
    return Services.put("/program", value);
  }

  delete(value) {
    return Services.post("/program/delete", value);
  }
}

export default new ItemSumberDayaApi();
