import { JenisAnggaranApi } from "api";
import { ActionButton, Input, TextArea } from "components";
import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

export const ModalAction = ({ setModalConfig, modalConfig }) => {
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const {
    values,
    handleChange,
    setValues,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    resetForm,
  } = useFormikContext();

  const getNomorHandler = () => {
    setIsNomorLoading(true);

    JenisAnggaranApi.getKode()
      .then(({ data }) => {
        const kode = data.data;

        setValues({
          ...values,
          kodeJenis: kode,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          kodeJenis: "",
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  useEffect(() => {
    modalConfig.type === "add" && getNomorHandler();

    return () => {
      setIsNomorLoading(false);
    };
  }, []);

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ data: {}, type: "add", show: false })}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            {modalConfig.type === "edit" ? "Ubah" : "Tambah"} Jenis Anggaran
          </Modal.Header>
          <Modal.Body>
            <Input
              label="Kode Jenis Anggaran"
              placeholder="Masukan kode jenis anggaran"
              name="kodeJenis"
              value={isNomorLoading ? "Memuat data ..." : values?.kodeJenis}
              readOnly
            />
            <Input
              label="Nama Jenis Anggaran"
              placeholder="Masukan nama jenis anggaran"
              name="namaJenis"
              value={values?.namaJenis}
              onChange={handleChange}
              error={Boolean(errors.namaJenis) && touched.namaJenis}
              errorText={
                Boolean(errors.namaJenis) &&
                touched.namaJenis &&
                errors.namaJenis
              }
            />
            <TextArea
              label="Keterangan"
              placeholder="Masukan keterangan"
              name="keterangan"
              rows="4"
              value={values?.keterangan}
              onChange={handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <ActionButton
              text="Batal"
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                resetForm({
                  values: { namaJenis: "", keterangan: "" },
                })
              }
            /> */}
            <ActionButton
              text={modalConfig.type === "edit" ? "Ubah" : "Simpan"}
              type="submit"
              size="sm"
              variant={modalConfig.type === "edit" ? "success" : "primary"}
              loading={isSubmitting}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
